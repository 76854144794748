/**=====================
     50. Tree  CSS Start
==========================**/
$mobile-width: 768px;
$tablet-width: 1024px;
$desktop-width: 1280px;

$mobile: "(max-width: 767px)";
$tablet: "(min-width: 768px) and (max-width: 1023px)";
$desktop: "(min-width: 1024px)";

$black-color: #000000;
$white-color: #ffffff;

$primary-grey-dark: #313443;
$primary-grey-medium: #41465a;
$primary-grey-light: #f3f3f6;
$primary-grey-semi-light: #e7e8ee;

$primary-red-color: #d72638;

@mixin mobile {
	@media (max-width: #{$mobile-width - 1px}) {
	  @content;
	}
  }
  
  @mixin tablet {
	@media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
	  @content;
	}
  }
  
  @mixin mobile-tablet {
	@media (max-width: #{$tablet-width - 1px}) {
	  @content;
	}
  }
  
  @mixin desktop {
	@media (min-width: #{$tablet-width}) {
	  @content;
	}
  }
  

.jstree-default{
	.jstree-clicked{
		background-color: lighten($primary-color , 42%);
		box-shadow: none;
		color: $dark-color;
	}
}
.vakata-context, .vakata-context ul{
	background-color: $white;
	border: none;
	box-shadow: 0 0 2px 2px $light-semi-gray;
	 li{
		 a{
			 .vakata-contextmenu-sep{
				 display: none;
			 }
			 &:hover{
				 background-color: lighten($primary-color , 45%);
				 box-shadow: None;
			 }
		}
	}
	.vakata-context-separator{
		display: none;
	}
}

.node-wrapper {
	white-space: pre;
	&.default-color {
	  &:hover {
		background-color: rgba(#d72638, 0.3) !important;
	  }
	}
	&.white-color {
	  &:hover {
		background-color: rgba(#ffffff, 0.5) !important;
	  }
	}
	&.gray-color {
	  &:hover {
		background-color: rgba(#41465a, 0.5) !important;
	  }
	}
  }
  .selected-node {
	&.default-color-selected {
	  background-color: rgba(#d72638, 0.5);
	}
	&.white-color-selected {
	  background-color: rgba(#ffffff, 0.5);
	}
	&.gray-color-selected {
	  background-color: rgba(#41465a, 0.5);
	}
  }
  
.ht_selected {
    background-color: rgba($primary-color, 0.1) !important;
}  
  .tree-node {
	display: flex;
	align-items: center;
	min-height: 40px;
	max-height: 40px;
  
	.tree-icon {
	  margin-right: 8px;
	  border-radius: 50%;
	  cursor: pointer;
	  background-repeat: no-repeat;
	  &.open-icon {
		//  background-image: url('../../icons/plus.svg');
		 background-image: url('../../images/plus.svg');
		width: 12px;
		height: 12px;
		background-size: contain;
	  }
	  &.close-icon {
		 background-image: url('../../images/minus.svg');
		width: 12px;
		height: 12px;
		background-size: contain;
	  }
	  &.empty-icon {
		 background-image: url('../../images/empty.svg');
		width: 12px;
		height: 12px;
		background-size: contain;
	  }
	}
  
	.node-content-wrapper {
	  cursor: pointer;
	  min-height: 40px;
	  max-height: 40px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  background-color: #ffffff;
	  width: 100%;
	  padding: 6px;
	  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	  transition: all 0.2s ease;
  
	  &:hover {
		background-color: rgba($primary-color, 0.1);
	  }
  
	  &.node-selected {
		border-left: 4px solid $primary-color;
	  }
  
	  .titles {
		display: flex;
		flex-direction: column;
		align-items: stretch;
  
		.node-title {
		  font-weight: 600;
		}
  
		.node-subtitle {
		  margin-top: 4px;
		  font-size: 10px;
		}
	  }
  
	  .children-length {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 24px;
		height: 16px;
		background-color: $primary-color;
		color: #ffffff;
		padding: 4px;
		font-size: 12px;
		border-radius: 16px;
		margin-left: 12px;
		font-weight: 600;
	  }
	}
  }
  
  .tree-wrapper {
	width: 50%;
	padding: 0 0 0 16px;
  
	&.simple {
	  overflow: auto;
	}
  
	 @include mobile {
	   width: 100%;
	   padding: 0 0 0 0;
	   margin-top: 16px;
	 }
  }
  
  .lib-icon {
	margin-top: 100px;
	.tree-icon {
	//   background-image: url('../../icons/tree.svg');
	  background-repeat: no-repeat;
	  width: 100px;
	  height: 100px;
	}
  }
  
  .code-wrapper {
	display: flex;
	width: 50%;
	background-color: #fff;
	min-height: 480px;
  
	 @include mobile {
	   width: 100%;
	 }
  
	.line-numbers {
	  font-size: 10px;
	  line-height: 12px;
	  padding-top: 18px;
	  color: rgba(#607D8B, 0.5);
	  text-align: right;
	  margin-left: 8px;
	}
  
	.rendered {
	  width: 100%;
	  line-height: 12px;
  
	  .code-view {
		white-space: pre-wrap;
		font-size: 10px;
		width: 100%;
	  }
	}
  }
  
  .hljs {
	display: block;
	overflow-x: auto;
	padding: 0.5em;
	color: #607D8B;
  }
  
  .hljs-comment,
  .hljs-quote {
	color: #5c6370;
	font-style: italic;
  }
  
  .hljs-doctag,
  .hljs-keyword,
  .hljs-formula {
	color: rgb(209, 98, 98);
  }
  
  .hljs-section,
  .hljs-name,
  .hljs-selector-tag,
  .hljs-deletion,
  .hljs-subst {
	color: #e03f4d;
  }
  
  .hljs-literal {
	color: #56b6c2;
  }
  
  .hljs-string,
  .hljs-regexp,
  .hljs-addition,
  .hljs-attribute,
  .hljs-meta-string {
	color: #5c9e2d;
  }
  
  .hljs-built_in,
  .hljs-class .hljs-title {
	color: #e6c07b;
  }
  
  .hljs-attr,
  .hljs-variable,
  .hljs-template-variable,
  .hljs-type,
  .hljs-selector-class,
  .hljs-selector-attr,
  .hljs-selector-pseudo,
  .hljs-number {
	color: #d19a66;
  }
  
  .hljs-symbol,
  .hljs-bullet,
  .hljs-link,
  .hljs-meta,
  .hljs-selector-id,
  .hljs-title {
	color: #4c86b6;
  }
  
  .hljs-emphasis {
	font-style: italic;
  }
  
  .hljs-strong {
	font-weight: bold;
  }
  
  .hljs-link {
	text-decoration: underline;
  }
  
/**=====================
     50. Tree CSS Ends
==========================**/