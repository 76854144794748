/**=====================
   59. Customizer  CSS Start
==========================**/
.customizer-links{
  .nav{
    i{
      margin-right: 7px;
    }
  }
  .nav-link{
    padding: 0;
    .settings{      
      color: var(--primary-color);       
      padding: 15px 24px;
      display: inline-block;
      cursor: pointer;
      margin: 0;      
      font-size: 18px;
      background-color: rgba($primary-color, 0.1)
    }
    &.active{
      .settings {
        color: $white;
        background-color: var(--primary-color);        
      }
    }
  }
}
.customizer-contain{
  direction: ltr;
  position: fixed;
  height: calc(100vh);
  top: 80px;
  width: 333px;
  right: -335px;
  background-color: $white;
  z-index: 99;
  font-family: $font-roboto;
  transition: all 0.3s ease;
  [dir="rtl"] & {
    left: -335px;
    right: unset;
  }
  &.open{
    right: 0px;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
    [dir="rtl"] & {
      left: 0px;
      right: unset;
    }
  }
  li{
    position: relative;
    &.active{
        border-color: var(--primary-color) !important;
        .badge-light{
            background-color: var(--primary-color) !important;
            color: $white !important;
        }
    }
  }
  h6{
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #212529;
  }
  .customizer-header{
    padding:15px 25px;
    border-bottom: 1px solid $light-color;
    .icon-close{
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      font-weight: 900;
      right: 30px;
      color: var(--primary-color);
    }
    h5{
      font-weight: 700;
      text-transform: uppercase;
      color: #313131;
    }
    p{
      color: #313131;
    }
  }
  .customizer-body{
    padding : 15px 25px;
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
    .layout-grid{
      &.customizer-mix{
        .color-layout{
          height: 70px;
          width: 80px;
          border: 1px solid #f6f7fb;
          border-radius: 5px;
          display: inline-block;
          margin-bottom: 7px;
          margin-right: 3px;
        }
      }
    }
    .tab-pane{
      > h6{
        position: relative;
        margin-top: 5px;
        padding-top: 25px;
        margin-bottom: 0px;
        border-top: 1px solid #f3f3f3;
        font-size: 15px;
        &:first-child{
          border-top: 0;
          padding-top: 0;
          margin-top: 0;
        }
      }
    }
    .nac-pills{
      border: 1px solid #f1f1f1;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-item{
        width: 33.33%;
        text-align: center;
        .nav-link{
          border-radius: 5px;
          &.active{
            background-color: var(--primary-color);
            color: $white;
          }
        }
      }
      .nav-link{
        padding: 0.2rem 1rem;
      }
    }
    li{
      cursor: pointer;
      opacity: 0.9;
      &.active{
        opacity: 1;
      }
    }
    .fade{
      &:not(.show) {
        display: none;
      }
    }
    .color-layout{
      height: 23px;
      width: 40px;
      border-radius: 5px;
      display: inline-block;
      margin-bottom: 7px;
      margin-right: 3px;
      border: 1px solid #b8b8b8;
      padding: 2px;
      > div{
        background-color: #158df7;
        height: 100%;
        width: 100%;
        border-radius: 3px;
      }
      &:nth-child(2){
        div{
          background-color: #0288d1;
        }
      }
      &:nth-child(3){
        div{
          background-color: #d64dcf;
        }
      }
      &:nth-child(4){
        div{
          background-color: #4c2fbf;
        }
      }
      &:nth-child(5){
        div{
          background-color: #7c4dff;
        }
      }
      &:nth-child(6){
        div{
          background-color: #3949ab;
        }
      }
      .body{
        .contain{
          position: absolute;
          height: auto;
          width: 100%;
          li{
            height: 1px;
            width: 65%;
            background-color: #158df7;
            border-radius: 5px;
            padding: 0;
            margin-left: 10px;
            margin-bottom: 4px;
            &:before{
              content: "";
              position: absolute;
              height: 1px;
              width: 3px;
              background-color: #158df7;
              left: -6px;
              border-radius: 5px;
            }
          }
        }
      }
      &[data-attr="color-2"]{
        div{
          background-color: #0288d1;
        }
      }
      &[data-attr="color-3"]{
        div {
          background-color: #d64dcf;
        }
      }
      &[data-attr="color-4"]{
        div {
          background-color: #4c2fbf;
        }
      }
      &[data-attr="color-5"]{
        div {
          background-color: #7c4dff;
        }
      }
      &[data-attr="color-6"]{
        div {
          background-color: #3949ab;
        }
      }
    }
    .main-layout{
      > li{
        height: 50px;
        width: 85px;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        background-color: $white !important;
        &+li{
          margin-left: 7px;
        }
      }
      .box-layout {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 16px;
        .body {
          .badge {
            left: -14px;
          }
        }
      }
    }
    .sidebar-type,.sidebar-setting{
      > li{
        height: 70px;
        width: 86px;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        background-color: $white !important;
        &+li{
          margin-left: 7px;
        }
      }
    }
    .sidebar-type {
      li {
        &:first-child {
          .body {
            ul {
              li {
                &.bg-dark, &.sidebar {
                  width: 100%;
                  height: 10px;
                  display: block;
                }
                &.bg-light, &.body {
                  height: calc(100% - 10px);
                }
              }
            }
          }
        }
        &:last-child{
          .simple-list{
            flex-direction: row;
          }
        }
      }
    }
    .sidebar-bg-settings{
      display: flex;
      align-items: center;
      justify-content: space-between;
      li{
        height: 100px;
        width: 100%;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        margin-right: 9px;
        box-shadow: 0 0 11px -2px rgba(128, 128, 128, 0.40);
        padding: 0;
      }
    }
  }
  .customizer-footer{
    position: absolute;
    bottom: 0;
    padding: 25px;
    width: 100%;
    border-top: 1px solid $light-color;
    background-color: $white;
  }
}
.customizer-color {
  .color-layout {
    .body{
      .body{
        ul{
          background-color: #f6f7fb;
        }
      }
    }
  }
  &.dark{
    .color-layout {
      background-color: #2f3c4e;
      .body{
        ul{
          background-color: #2f3c4e;
        }
      }
    }
  }
}
.layout-grid:not(.customizer-color){
  li{
    padding: 5px;
    > .header{
      height: 9px;
      border-radius: 3px;
      position: relative;
      ul{
        position: absolute;
        top: -8px;
        left: 5px;
        >li{
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 5px;
          top: calc(50% - 3px);
          background-color: $danger-color;
          margin-right: 1px;
          padding: 0;
          &:nth-child(2){
            background-color: $warning-color;
          }
          &:nth-child(3){
            background-color: $success-color;
          }
        }
      }
    }
    > .body{
      background-color: $light-color;
      height: calc(100% - 16px);
      border-radius: 3px;
      position: relative;
      margin-top: 5px;
      text-align: center;
      line-height: 3.1;
      ul{
        height: 100%;
        background-color: $white;
        .sidebar{
          width: 18px;
          height: 100%;
          border-radius: 3px;
          display: inline-block;
          margin-right: 3px;
          padding: 0;
          &.compact{
            width: 12px;
            &~.body {
              width: calc(100% - 19px);
            }
          }
          &.compact-icon{
            width: 7px;
            &~.body {
              width: calc(100% - 14px);
            }
          }
        }
        .body{
          width: calc(100% - 25px);
          padding: 0;
          margin: 0;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          .badge{
            position: absolute;
          }
        }
      }
    }
  }
}
/**=====================
     59.Customizer CSS Ends
==========================**/
