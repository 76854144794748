
/**=====================
   64. Button Builder CSS start Here
==========================**/

//Button Generator css



//urls
$url_0: url(../../images/button_builder/select.png);
$url_2: url(../../images/button_builder/checkbox-sprite.png);
$url_3: url(../../images/button_builder/rainbow.png);
$url_4: url(../../images/button_builder/scroll.png);
$url_5: url(../../images/button_builder/hex_bg.gif);
$url_6: url(../../images/button_builder/btn-ok.png);
$url_8: url(../../images/button_builder/header.jpg);

.button-builder {
  %extend-col1 {
    background: $url_6 no-repeat scroll 0 0 $danger-color;
    border: 0 none;
    cursor: pointer;
    display: block;
    height: 25px;
    left: 235px;
    outline: 0 none;
    position: absolute;
    top: 133px;
    width: 75px;
  }

  %extend-col2 {
    background-color: $dark-color;
    background-image: none;
    color: $white;
    text-decoration: none;
  }

  .button-generator-bx
  {
    position: fixed;
    width: 340px;
    max-height: 500px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .grp-btns {
    input {
      display: none !important;
    }
  }

  .custom-button-color {
    display: none;
    .form-control {
      width: 100px;
      border-radius: 5px 0 0 5px;
    }
  }
  .color-slelector {
    float: left;
    height: 39px;
    position: relative;
    width: 39px;
    div {
      height: 33px;
      left: 0;
      position: absolute;
      top: 0;
      width: 33px;
      transform: scale(1.4);
      z-index: 1;
      background: $url_0 repeat scroll 0 0 rgba(0, 0, 0, 0);
    }
  }
  .custom-radio {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    z-index: 1;
    top: 3px;
    border: 1px solid var(--primary-color);
    border-radius: 100%;

    &:hover {
      background-position: 0 -16px;
    }
    &.selected {
      background-color: var(--primary-color);
    }
    input[type="radio"] {
      margin: 1px;
      position: absolute;
      z-index: 2;
      cursor: pointer;
      outline: none;
      opacity: 0;
      filter: alpha(opacity=0);
    }
  }
  .custom-checkbox {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    z-index: 1;
    top: 3px;
    background: $url_2 no-repeat 0 0 transparent;
    &:hover {
      background-position: 0 -16px;
    }
    &.selected {
      background-position: 0 -32px;
    }
    input[type="checkbox"] {
      margin: 0;
      position: absolute;
      z-index: 2;
      cursor: pointer;
      outline: none;
      opacity: 0;
      filter: alpha(opacity=0);
    }
  }
  .colorpicker-hex {
    background: $url_5 no-repeat scroll right center rgba(0, 0, 0, 0);
    left: 220px;
    padding-left: 15px;
    position: absolute;
    top: 98px;
    width: 75px;
    height: 25px;
    box-sizing: content-box;
    input {
      background: transparent;
      border: medium none;
      color: var(--theme-font-color);
      display: block;
      box-shadow: none;
      font-size: 13px;
      height: 17px;
      outline: medium none;
      padding: 5px;
      top: 0;
      right: 1px;
      text-align: left;
      text-transform: uppercase;
      width: 65px;
      box-sizing: content-box;
    }
  }
  .colorpicker-hue {
    background: $url_3 no-repeat scroll center center rgba(0, 0, 0, 0);
    cursor: default;
    height: 150px;
    left: 171px;
    position: absolute;
    top: 13px;
    width: 35px;
    div {
      background: $url_4 no-repeat scroll left top rgba(0, 0, 0, 0);
      height: 16px;
      left: 0;
      margin-top: -8px;
      overflow: hidden;
      position: absolute;
      width: 35px;
    }
  }
  .colorpicker-new-color {
    height: 75px;
    left: 235px;
    position: absolute;
    top: 12px;
    width: 73px;
    border: 1px solid $light-gray;
  }
  .colorpicker-field {
    display: none;
  }
  .colorpicker-current-color {
    display: none;
  }
  .colorpicker-submit {
    cursor: pointer;
    display: block;
    height: 25px;
    left: 235px;
    position: absolute;
    top: 133px;
    width: 75px;
    background-color: var(--primary-color);
    border-radius: 5px;
    &:before{
      content: "Ok";
      position: absolute;
      width: 100%;
      text-align: center;
      padding: 3px 10px;
      color: $white;
    }
    &:visited {
      @extend %extend-col2;
    }
    &:hover {
      background-color:  darken($primary-color ,10%);
    }
  }
  .box {
    background-color: $white;
    margin-bottom: 20px;
    padding: 30px;
    text-align: center;
    border: 1px solid $light-semi-gray;
    border-radius: 5px;
  }
  .button-preview {
    min-height: 145px;
    h2 {
      color: $dark-color;
      font-size: 18px;
      font-weight: normal;
      margin: 0;
    }
    &.affix {
      top: 58px;
      position: fixed;
      width: 395px;
      z-index: 999;
    }
  }
  #result {
    padding-top:30px;
  }
  .well {
   .language-markup
   {
     word-wrap: break-word;
     white-space: normal;
   }
    .language-css
    {
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
  pre {
    &.well {
      margin: 0;
      padding: 0;
      background: $white !important;
      overflow: unset;
    }
  }
  .copy {
    margin-top: 30px;
    position: relative;
    .zclip {
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto !important;
    }
    a {
      background-color: var(--primary-color);
      color: $white;
      font-weight: bold;
      display: inline-block;
      padding: 0.375rem 1.75rem;
      border-radius: 5px;
    }
  }

  .sucess-msg {
    height: 30px;
    line-height: 30px;
    display: none;
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-bottom: -30px;
  }
  .code-wrapper {
    position: relative;
    width: 100%;
    margin: 0;
  }
  .column-left {
    float: left;
    width: 655px;
  }
  .column-right {
    float: right;
    width: 395px;
    position: relative;
    padding-top: 175px;
  }
  code {
    font-size: 11.5px !important;
  }
  .hint-label {
    float: left;
    padding: 4px 0 0 020px;
    font-style: italic;
  }

  .button-style {
    margin-top: 1px !important;
  }
  .ad-box {
    height: 90px;
    overflow: hidden;
    margin: 0 0 20px;
  }
  ul.the-icons {
    margin: 0 0 0 -8px;
    padding-left: 0;
    font-size: 13px;
    li {
      float: left;
      line-height: 15px;
      cursor: pointer;
      text-align: center;
      list-style: none outside none;
      padding: 5px;
      border: 1px dotted $light_gray;
      color: var(--dark-gray);
      width: 40px;
      transition: all 0.2s ease-in-out 0s;
      margin: 2px;
      em {
        display: none;
      }
      &:hover {
        background: $white;
        box-shadow: 0 0 3px $light-color;
        &.active {
          background: $white;
          box-shadow: 0 0 3px $light-color;
        }
      }
    }
  }
}
.button-builder-wrap{
    .mb-3{
        .btn{
          margin-right: 15px;
          margin-bottom: 15px;
          min-width: 100px;
        }
    }
}
.grp-btns {
 .btn-light:not([disabled]):not(.disabled).active.active{
    color: black;
  }
}
.colorpicker{
  display: none;
}
/*==============================
 64. Button Builder CSS End
 ===========================*/
