$header-size: 135px;

/**=====================
    05. Header CSS Start
==========================**/
%common {
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}
%for-animated-hover {
  box-shadow: 0 0 2px 2px #f1f3f7;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  &:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }
  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid var(--light-background);
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}
%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $white;
  color: var(--light-background);
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}
/*======= Page Header css Start ======= */
.page-wrapper {
  .page-main-header {
    background-color: var(--main-header-bg-color);
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    width: 100%;
    display: flex;
    align-items: center;
    transition: $sidebar-transition;
    &.open {
      margin-left: 0;
      width: 100%;
      transition: $sidebar-transition;
    }
  }
  .page-body-wrapper {
    .page-body {
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      // background-color: $light;
      transition: 0.5s;
    }
    .page-header {
      padding-top: $page-header-padding;
      padding-bottom: $page-header-padding;
      .row {
        align-items: center;
        h3 {
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          font-weight: $page-title-font-weight;
          text-transform: $page-title-text-tranform;
          small {
            display: $page-small-title-display;
            font-size: $page-small-title-font-size;
            margin-bottom: $page-small-title-margin-bottom;
            text-transform: $page-small-title-text-transform;
            color: var(--theme-body-sub-title-color);
          }
        }
      }
      .breadcrumb {
        background-color: transparent;
        padding: 0;
        margin-top: 0;
        margin-bottom: 5px;
        li {
          color: $black;
          font-size: 13px;
          letter-spacing: 1px;
          font-weight: 500;
        }
        .breadcrumb-item {
          & + .breadcrumb-item {
            &:before {
              content: $breadcrumb-content;
            }
          }
          a {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}
/*======= Page Header css ends  ======= */
.page-wrapper {
  .page-main-header {
    .main-header-right {
      .nav-right {
        .onhover-show-div {
          .dropdown-title {
            h6 {
              display: block;
              text-align: start;
            }
          }
        }
      }
      .bookmark-flip {
        .bookmark-dropdown {
          .bookmark-content {
            &:hover {
              .bookmark-icon {
                a {
                  svg {
                    stroke: var(--primary-color);
                  }
                }
              }
            }
            .bookmark-icon {
              align-items: center;
              border: 1px solid #eee;
              border-radius: 100%;
              display: flex;
              height: 40px;
              justify-content: center;
              margin: 0 auto 8px;
              width: 40px;
            }
          }
        }
      }
      .nav-right {
        .onhover-show-div:not(.profile-dropdown) {
          &.bookmark-flip {
            .bookmark-dropdown {
              .ProfileCard-avatar {
                svg {
                  width: 18px;
                  height: 18px;
                  position: unset;
                  stroke: var(--theme-body-font-color);
                  opacity: 1;
                }
              }
              .ProfileCard-realName {
                i {
                  color: var(--theme-body-font-color);
                }
                .starred {
                  color: var(--btn-search-bg-c) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

/**======Main Header css Start ======**/
.page-main-header {
  max-width: 100vw;

  .main-header-right {
    width: 100%;
    display: flex;
    align-items: center;
    padding: $main-header-right-padding;
    border-radius: 20px;
    position: relative;
    .main-header-left {
      margin-right: 30px;
    }
    .vertical-mobile-sidebar {
      display: none;
    }
    .nav-left {
      i {
        margin-right: 20px;
      }
      input:focus {
        outline: 0 !important;
      }
    }
    .nav-right {
      text-align: left;
      padding-left: $main-header-right-nav-right;
      padding-right: $main-header-right-nav-right;
      ul {
        li {
          svg {
            vertical-align: $main-header-right-nav-icon-align;
            width: $main-header-right-nav-icon-size;
            height: $main-header-right-nav-icon-size;
            cursor: pointer;
            path {
              color: var(--main-header-right-nav-icon-color);
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      // bookmark start
      .bookmark-flip {
        width: unset !important;
        box-shadow: unset !important;
        background-color: transparent !important;
        overflow: initial;
      }
      .flip-card {
        width: 220px;
        height: 245px;
        background-color: transparent;

        .flip-card-inner {
          position: relative;
          height: 100%;
          text-align: center;
          transition: transform 0.6s;
          transform-style: preserve-3d;

          .front,
          .back {
            position: absolute;
            backface-visibility: hidden;
            border-radius: 10px;
            overflow: hidden;
            width: 300px;
            height: auto;
            @media screen and (max-width: 575px) {
              width: 230px;
              [dir="rtl"] & {
                right: 155px;
              }
            }
            button {
              background-color: transparent;
              color: $white;
              border: none;
              @media screen and (max-width: 575px) {
                padding: 10px;
              }
              &:focus {
                outline: transparent;
              }
            }
          }

          .front {
            background-color: $white;
            transition: 0.3s;
            box-shadow: 0 0 2px 2px #f1f3f7;
            &.dropdown-title {
              > svg {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 0;
                right: 0;
                opacity: 0.05;
                stroke: var(--primary-color);
              }
            }

            svg {
              stroke: $dark-color;
              height: 18px;
              opacity: 1;
              position: static;
              -webkit-transform: none;
              -webkit-transform: initial;
              transform: none;
              width: 18px;
              &:hover {
                stroke: $white;
                transition: all 0.3s ease;
              }
            }
          }

          .back {
            background-color: $white;
            transform: rotateY(180deg);
            box-shadow: 0 0 2px 2px #f1f3f7;
            li {
              &:first-child {
                margin-top: 0;
                height: 180px !important;
              }
              &:last-child {
                padding: 15px 0;
                border-top: 1px solid $light-gray;
              }
              .suggestion-box {
                li {
                  &:first-child {
                    height: auto !important;
                  }
                }
              }
            }

            button {
              width: 100%;
            }

            .flip-back-content {
              input {
                border-radius: 25px;
                padding: 6px 12px;
                width: 100%;
                border: 1px solid $light-gray;
              }
            }
          }

          &.flipped {
            transform: rotateY(180deg);

            .front {
              opacity: 0;
            }
          }
        }
      }
      // bookmark end
      .onhover-show-div {
        li {
          display: block;
        }
      }
      > ul {
        padding: 0 0;
        .search-form {
          position: relative;
          .mobile-search {
            svg {
              color: var(--primary-color);
            }
          }
          .form-group {
            margin-bottom: 0;
            &:before,
            &:after {
              display: none;
            }
          }
          svg {
            position: absolute;
            right: 13px;
            top: 11px;
          }
        }
      }
      &.right-menu {
        ul {
          justify-content: flex-end;
          > li {
            &:nth-child(2) {
              > a {
                .sub-arrow {
                  display: none;
                }
              }
            }
          }
          li {
            position: relative;

            &:last-child {
              border: none;
              display: flex;
            }
            .search-form {
              input {
                padding: 8px 20px;
                border-radius: 5px;
                background-color: transparent;
                &:focus {
                  box-shadow: none;
                  border: $card-border-color;
                }
              }
            }
            .user-header {
              img {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
              }
              .flex-grow-1 {
                text-align: left;
                span {
                  + span {
                    font-size: 9px;
                    font-weight: 600;
                    color: var(--light-text);
                  }
                }
              }
            }
            .notification {
              position: absolute;
              top: -2px;
              right: -9px;
              padding: 5px 7px;
            }
          }
          &.click-menu {
            > li {
              label {
                padding: 0;
              }
            }
          }
        }
        ul.click-menu {
          > li {
            label {
              &:after {
                display: none;
              }
            }
          }
        }
      }
      .icon-user {
        font-size: 16px;
      }
      > ul {
        display: flex;
        align-items: center;
        > li {
          position: relative;
          padding: 6px 10px;
          border: $card-border-color;
          border-radius: 4px;
          margin-right: 20px;
          @media screen and (max-width: 1199px) {
            margin-right: 10px;
          }
          .badge {
            padding: 4px 8px;
          }
          span {
            letter-spacing: 0.9px;
            .fa {
              color: var(--gray-60);
            }
            ul {
              left: inherit;
              right: -10px;
              width: 130px;
              &:before,
              &:after {
                left: inherit;
                right: 10px;
              }
              li {
                display: block;
                a {
                  font-size: 14px;
                  color: $dark-color;
                  i {
                    margin-left: 10px;
                    font-size: 13px;
                    color: $dark-color;
                  }
                }
              }
            }
          }
        }
        .flag-icon {
          font-size: 16px;
        }
      }
      .chat-dropdown {
        width: 300px;
        top: 49px;
        right: 0;
        left: unset;
        @media screen and (max-width: 575px) {
          width: 270px;
        }
        li {
          padding: 0 15px;
          img {
            width: 40px;
          }
          &:last-child {
            padding-top: 20px;
            border-top: $card-border-color !important;
          }
          .d-flex {
            .media-body{
              a{
                color: var(--theme-body-font-color);
              }
            }
            img {
              width: 40px;
              position: relative;
            }
            .status-circle {
              width: 9px;
              height: 9px;
              position: absolute;
              top: 2px;
              left: 42px;
              border-radius: 50%;
              border: 2px solid $white;
              [dir="rtl"] & {
                right: 42px;
                left: unset;
              }
            }
          }
          + li {
            margin-top: 15px;
          }
        }
        &:before,
        &:after {
          right: 10px !important;
          left: unset !important;
        }
      }
      .notification-dropdown {
        top: 49px;
        width: 300px;
        left: -10px !important;
        @media screen and (max-width: 575px) {
          width: 270px;
        }
        li {
          padding: 15px !important;
          p {
            a{
              color: var(--theme-body-font-color);
            }
            span {
              color: var(--light-font);
            }
          }
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            padding-top: 0 !important;
          }
          h6 {
            margin-bottom: 2px;
          }
        }
      }
      .profile-dropdown {
        width: 160px;
        top: 57px;
        left: -12px;
        li {
          padding: 10px !important;
          &:hover {
            color: var(--primary-color);
            svg {
              path {
                color: var(--primary-color);
              }
            }
          }
          &:first-child {
            border-bottom: $card-border-color !important;
          }
          &.f-12 {
            padding-left: 15px !important;
            svg {
              width: 14px;
            }
          }
          &:last-child {
            border-top: $card-border-color !important;
            padding-top: 10px !important;
          }
          + li {
            + li {
              padding-top: 0 !important;
            }
          }
          svg {
            vertical-align: bottom;
            margin-right: 10px;
            margin-top: 0;
          }
          a{
            color: var(--theme-body-font-color);
            &:first-child{
              font-size: 13px;
            }
          }
        }
      }
    }
    li {
      display: inline-block;
      position: relative;
    }
  }
}
.onhover-dropdown {
  cursor: pointer;
  position: relative;
  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
}
.onhover-show-div {
  top: 49px;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
  li {
    a {
      svg {
        margin-top: 0 !important;
        path,
        line {
          color: var(--theme-body-font-color) !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/

/** ===== Vertical Menu css Start=====**/
.vertical-menu-main {
  background: white;
  position: fixed;
  top: 88px;
  z-index: 1;
  width: 100%;
}
.vertical-menu-mt {
  margin-top: 148px !important;
  margin-left: 0 !important;
  .custom-card {
    .card-header {
      img {
        margin-top: -146px !important;
      }
    }
  }
}
.vertical-menu-main {
  #main-nav {
    #main-menu {
      li {
        a {
          svg {
            width: 15px;
            height: 15px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .mega-menu {
    width: 1500px !important;
    max-width: 1500px !important;
    padding: 0;
    left: -120px !important;
    top: 65px !important;
  }
}
/** ===== Vertical Menu css Ends=====**/

/* ========sidebar icon vertical menu start=======*/

.compact-switch {
  display: none;
}
.sidebar-toggle-btn {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}
/* ========sidebar icon vertical menu end=======*/

/*=======Mega menu css start=======*/
.mega-menu {
  perspective: inherit;
  position: static !important;
  padding-top: 5px;
  .default-according {
    .card {
      .btn-link {
        font-weight: 500;
        color: gray;
      }
      .card-body {
        font-size: 12px;
      }
    }
  }
  .onhover-show-div {
    width: 1500px;
  }
  .card-body {
    padding: 20px;
  }
  .title {
    color: var(--theme-body-font-color);
    font-weight: 600;
    border-bottom: 1px solid $light-semi-gray;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  div {
    > div {
      a {
        margin-bottom: 0px;
        display: inline-block;
        color: var(--theme-body-sub-title-color);
      }
    }
  }
  .custom-nav-img {
    position: static;
    .desk {
      line-height: 25px;
      font-size: 14px;
      color: #6e7073;
    }
  }
  .list-unstyled {
    div {
      a {
        padding: 8px 35px 8px 0;
        transition: $sidebar-transition;
        font-weight: 500;
        &:hover {
          padding: 8px 35px 8px 10px;
          transition: $sidebar-transition;
        }
      }
    }
  }
  .galleria div {
    margin-bottom: 5px;
    &:hover {
      transform: translateY(4px) scale(1.04);
    }
  }
  .galleria-list .galleria > div {
    width: 25%;
    font-size: 12px;
    float: left;
    color: $black;
    transition: ease-in 0.3s;
    > a {
      display: block;
      margin: 5px 0 10px;
      padding: 0 0 !important;
    }
    img {
      border-radius: 3px;
      max-width: 100%;
    }
    .username {
      font-size: 12px;
      color: var(--theme-body-font-color);
      margin-bottom: 5px;
      small {
        display: block;
        font-size: 11px;
        color: var(--theme-body-sub-title-color);
        margin-top: 3px;
      }
    }
  }
  .drilldown {
    overflow: hidden;
    width: 100%;
    padding: 0;
    transform: translate3d(0, 0, 0);
    a {
      width: 100%;
      &:hover {
        background-color: transparent;
        color: var(--primary-color);
      }
    }
  }
  .drilldown-sub {
    display: none;
  }
  .drilldown-back {
    font-weight: bold;
  }
}
/*=======Mega menu css end=======*/

// responsive header
@media only screen and (max-width: 1366px) {
  .profile-dropdown {
    &.onhover-show-div {
      right: 0;
      left: unset !important;
      &:before,
      &:after {
        right: 10px !important;
        left: unset !important;
      }
    }
  }
}
/**=====================
    05. Header CSS Ends
==========================**/
