/**=====================
    80. avatars CSS start
==========================**/
.avatars{
    .avatar{
        display: inline-block;
        margin-right: 10px;
        position: relative;
        width: auto;
        &:last-child{
            margin-right: 0;
        }
        .b-r-30 {
            border-radius: 30px;
        }
        .b-r-35 {
            border-radius: 35px;
        }
        .b-r-25 {
            border-radius: 25px;
        }
        .b-r-15 {
            border-radius: 15px;
        }
        &.ratio{
            img{
                width: auto;
                position: unset;
                top: unset;
                left: unset; 
            }
        }
        .height-100{
            height: 100px;
        }
        .height-90{
            height: 90px;
        }
        .height-80{
            height: 80px;
        }
        .height-70{
            height: 70px;
        }
        .height-60{
            height: 60px;
        }
        .height-50{
            height: 50px;
        }
        .status{
            position: absolute;
            background-color: var(--light-text);
            bottom: 0;
            right: 4px;
            border-radius: 100%;
            border: 1px solid $white;
        }
        .status-100 {
            width: 25px;
            height: 25px;
        }
        .status-90 {
            width: 20px;
            height: 20px;
        }
        .status-80 {
            width: 15px;
            height: 15px;
        }
        .status-70 {
            width: 12px;
            height: 12px;
        }
        .status-60 {
            width: 10px;
            height: 10px;
        }
        .status-50 {
            width: 9px;
            height: 9px;
        }
        .status-40 {
            width: 8px;
            height: 8px;
        }
        .status-30 {
            width: 7px;
            height: 7px;
        }
    }
}
.customers{
    ul{
        li{
            img{
                border: 2px solid $white;
                box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
                transition: 0.5s;
                &:hover{
                    transform: translateY(-4px) scale(1.02);
                    transition: 0.5s;
                }
            }
            + li{
                margin-left: -22px;
            }
            &:nth-child(4){
                margin-left: -4px;
            }
        }
    }
    &.avatar-group{
       margin-right: 30px;
        &:last-child{
            margin-right: 0;
        }
    }
}
.avatar-showcase{
    margin-bottom: -10px;
    .avatars{
        .avatar{
            margin-bottom: 10px;
        }
    }
    .avatar-group{
        margin-bottom: 10px;
    }
}
/**=====================
     80. avatars CSS Ends
==========================**/
